tr {
  color: #4b4f58;
}

.table thead th {
  color: #4b4f58;
  font-weight: normal;
  font-size: 0.9rem;
}

.control.has-icons-left .icon {
  color: #4b4f58;
}

.icon.is-small.is-right {
  right: 12px
}

.table tbody tr td {
  font-size: 0.85rem;
}

.input {
  color: #4b4f58;
}

.control.has-icons-left .input {
  color: #4b4f58;
  font-size: 0.85rem;
}

.control.has-icons-left span {
  height: 30px;
  font-size: 0.85rem;
}

.columns .column .field label {
  font-size: 0.9rem;
}

.table tbody tr td span {
  font-size: 0.85rem;
}

.github-inner {
  margin-right: 0.2em;
  height: 1em;
  width: 2em;
  opacity: 0.5;
  vertical-align: middle;
  border-style: none;
}

.github-link {
  text-size-adjust: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(102, 102, 102);
  font-size: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.5em 1em;
  margin: 0.2em;
  border-radius: 0.2em;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(203, 203, 203);
  border-image: initial;
  overflow: hidden;
}

.disclaimer p {
  font-size: 0.85rem;
}