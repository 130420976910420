@charset "utf-8"

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

$color1: #ffcdb2ff
$color2: #ffb4a2ff
$color3: #e5989bff
$color4: #b5838dff
$color5: #6d6875ff

// Theme
$purple: #6b717f
$pink: #dadbdf
$beige-light: #9095a3
$beige-lighter: #c2c5cc

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif
$grey-dark: $purple
$grey-light: $pink
$primary: $purple

// // Update some of Bulma's component variables
$body-background-color: #eff0eb
$control-border-width: 1px
$input-border-width: 1px
$input-hover-border-color: $beige-light

// Tabs
$tabs-link-active-border-bottom-color:	$purple
$tabs-link-active-color: $purple
$tabs-toggle-link-active-background-color: $purple
$tabs-toggle-link-active-border-color: $purple
$tabs-link-color:	$beige-lighter
$tabs-link-hover-border-bottom-color:	$beige-light
$tabs-link-hover-color:	$beige-light


// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass'
@import '../node_modules/bulma/sass/base/_all.sass'
@import '../node_modules/bulma/sass/elements/button.sass'
@import '../node_modules/bulma/sass/elements/container.sass'
@import '../node_modules/bulma/sass/elements/title.sass'
@import '../node_modules/bulma/sass/elements/table.sass'
@import '../node_modules/bulma/sass/components/navbar.sass'
@import '../node_modules/bulma/sass/layout/hero.sass'
@import '../node_modules/bulma/sass/layout/section.sass'
@import '../node_modules/bulma/sass/components/tabs.sass'
@import '../node_modules/bulma/sass/grid/columns.sass'
@import '../node_modules/bulma/sass/form/_all.sass'
@import '../node_modules/bulma/sass/elements/_all.sass'
